import React from 'react';
import { Global, css } from '@emotion/react';
import { CartProvider } from 'use-shopping-cart';

// import getStripe from '../utils/stripejs';
import { ThemeProvider } from '../global/ThemeContext';
import GlobalStateProvider from '../global/GlobalStateProvider'

function App({ children }) {
	return (
		<ThemeProvider>
			<CartProvider
				cartMode="client-only"
				stripe={process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}
				successUrl={`${process.env.GATSBY_CURRENT_URL}/thanks`}
				cancelUrl={`${process.env.GATSBY_CURRENT_URL}/`}
				currency="USD"
				allowedCountries={['US']}
				billingAddressCollection={true}
			>
				<GlobalStateProvider>
					<Global styles={globalStyles} />
					{children}
				</GlobalStateProvider>
			</CartProvider>
		</ThemeProvider>
	);
}

export default App;

const globalStyles = css`
	button {
		border: none;
	}

	body {
		background-color: var(--color-background);

		transition: ease-in-out color 0.3s, ease-in-out background 0.3s;

		color: var(--color-text);
	}

	svg {
		overflow: visible;
		fill: var(--color-text);
	}

	a {
		color: var(--color-text);

		&:hover {
			color: var(--color-highlight);
		}
	}

	button {
		transition: ease-in-out box-shadow 0.3s;
		display: inline-block;
		cursor: pointer;
		text-decoration: none;
		height: fit-content;
		padding: 0.1rem 0.8rem;
		border-radius: 3px;
		box-shadow: 0 0 0 3px var(--color-text);
		letter-spacing: 0.15rem;
		font-size: 1.1rem;
		font-family: 'Balsamiq Sans', 'Nunito';
		background: var(--color-pure);
		color: var(--color--text);

		&:hover {
			background: var(--color-text);
			color: var(--color-pure);
		}
	}
`;
