import React, { useState } from 'react';
import Context from './context';

const GlobalStateProvider = ({ children }) => {
	const [cartOpen, setCartOpen] = useState(false);

	return (
		<Context.Provider value={{cartOpen, setCartOpen }}>
			{children}
		</Context.Provider>
	);
};

export default GlobalStateProvider;
