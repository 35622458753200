export const formatPrice = (amount, currency) => {
	let price = (amount / 100).toFixed(2);
	let numberFormat = new Intl.NumberFormat(['en-US'], {
		style: 'currency',
		currency: currency,
		currencyDisplay: 'symbol',
	});
	return numberFormat.format(price);
};

export const MaxPriceItemsSold = 10;

export const COLORS = {
	text: {
		light: 'hsl(0deg, 0%, 10%)', // white
		dark: 'hsl(0deg, 0%, 100%)', // near-black
	},
	background: {
		light: '#f7f7f7', // white
		dark: '#0e141b', // navy navy blue
	},
	primary: {
		light: 'hsl(340deg, 100%, 40%)', // Pinkish-red
		dark: 'hsl(50deg, 100%, 50%)', // Yellow
	},
	secondary: {
		light: 'hsl(250deg, 100%, 50%)', // Purplish-blue
		dark: 'hsl(190deg, 100%, 40%)', // Cyan
	},
	pure: {
		light: 'white',
		dark: 'black',
	},
	highlight: {
		light: '#717171',
		dark: '#b9bdc2',
	},
	shadow: {
		light: 'rgba(0,0,0,0.2)',
		dark: 'rgba(255,255,255,0.2)',
	},
	// Grays, scaling from least-noticeable to most-noticeable
	gray300: {
		light: 'hsl(0deg, 0%, 70%)',
		dark: 'hsl(0deg, 0%, 30%)',
	},
	gray500: {
		light: 'hsl(0deg, 0%, 50%)',
		dark: 'hsl(0deg, 0%, 50%)',
	},
	gray700: {
		light: 'hsl(0deg, 0%, 30%)',
		dark: 'hsl(0deg, 0%, 70%)',
	},
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
